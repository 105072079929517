<template>
    <div class="row">
        <div class="col-md-12">
            <dashboard-box :back="true" back-component-name="management.invoice_custom_approval_type.index">
                <template v-slot:title>
                    <span>{{ $t(id != null ? "invoiceCustomApprovalTypes.edit_invoice_custom_approval_type" :
                    "invoiceCustomApprovalTypes.create_invoice_custom_approval_types") }}</span>
                </template>
                <template v-slot:preview>
                    <div class="row d-flex" v-if="statusesProxy">
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                            v-for="(value, index) in statusesProxy.translations" :key="index">
                            <text-input :key="index"
                                :title="`${$t('custom_invoice_approval_statuses.status_name')}(${getLanguageById(value.language_id).name})`"
                                :model.sync="value.name"
                                :placeholder="`${$t('custom_invoice_approval_statuses.status_name')}(${getLanguageById(value.language_id).name})`"
                                :is-valid="value != null && value.name.length !== 0 ? true : null"></text-input>
                        </div>

                    </div>

                    <div class="d-flex justify-content-center mt-5">
                        <button-with-icon class="mr-3 danger-border-1px" @onClick="$router.go(-1)"
                            :text="$t('general.cancel')" size="lg" :icon-name="null"></button-with-icon>
                        <button-with-icon @onClick="createOrUpdatestatuses"
                            :text="$t(id != null ? 'general.save' : 'general.create')" size="lg" :icon-name="null"
                            :disabled="!isValidToCreate"></button-with-icon>
                    </div>
                </template>
            </dashboard-box>
        </div>
    </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import {
    CREATE_ITEM,
    CURRENT_ITEM,
    ERROR, GET_ITEMS,
    ITEMS,
    SET_ITEMS,
    GET_ITEM_DETAIL_BY_ID,
    LOADING, SET_CURRENT_ITEM, SUCCESS, UPDATE_ITEM_BY_ID
} from "@/core/services/store/panel-managment/crud/panel.management.module";
import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import { LANGUAGE } from "@/core/services/store/system-options.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

export default {
    name: "PackingListCreateEdit",
    components: {
        ButtonWithIcon,
        TextInput,
        SelectInput,
        CustomMultiSelect,
        DashboardBox
    },
    computed: {
        ...mapGetters({
            loading: LOADING,
            error: ERROR,
            success: SUCCESS,
            statuses: CURRENT_ITEM,
            language: LANGUAGE,
        }),
        isValidToCreate() {
            if (this.statuses.translations == null || this.statuses.translations.length === 0) return false;

            for (let i = 0; i < this.statuses.translations.length; i++) {
                if (this.statuses.translations[i].name == null || this.statuses.translations[i].name.length === 0) {
                    return false;
                }
            }

            return true;
        },
        statusesProxy: {
            get() {
                if (this.id != null && this.statuses) {
                    this.type_id = this.type_id;
                    this.statusesItems = this.statuses.cache_items;
                }

                return this.statuses;
            },
            set(value) {
                this.setStatuses(value);
            }
        }
    },
    data() {
        return {
            id: null,
            statusesItems: [],
            type_id: this.id ? this.type_id : 5,
            tempItem: {
                translations: []
            },
        };
    },
    methods: {
        ...mapMutations({
            setstatuses: SET_CURRENT_ITEM,
        }),
        createOrUpdatestatuses() {
            if (this.isUserGranted('InvoiceCustomApprovalStatus', ['update', 'create'], true, true)) {
                let contents = [];
                this.statusesProxy.translations.forEach(item => {
                    contents.push(item)
                })

            // If statuses_category 1 is statusess, if 2 is general statuses code
            let payload = {
                url: this.id == null ? 'api/statuses' : 'api/statuses/' + this.id,
                id: this.id,
                contents: {
                    translations: this.statusesProxy.translations,
                    type_id: this.type_id,
                },
                redirectRouteName: 'management.invoice_custom_approval_statuses.index',
                successMessage: this.$t(this.id == null ? 'general.successfully_created' : 'general.successfully_updated'),
            }
                this.$store.dispatch(this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID, payload);
            }
        },
    },
    mounted() {
        if (!this.isUserGranted('InvoiceCustomApprovalStatus', ['update', 'create'], true, true)) {
            return false;
        }
        
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.$t("general.dashboard"), route: "dashboard" },
            { title: this.$t("menu.management.packing_list_statuses"), route: "management.packing_list_statuses.index" },
            { title: this.$t(this.id != null ? "packing_list_statuses.edit_packing_list_statuses" : "packing_list_statuses.create_packing_list_statuses") },
        ]);
        this.id = this.$route.params.id;

        if (this.id != null) {
            let payload = {
                url: 'api/statuses/' + this.id,
                id: this.id,
                redirectRouteOnNotFound: 'management.invoice_custom_approval_statuses'
            }

            this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload)
        }


        
        this.languages = this.convertArrayToObjectByKey((this.systemLanguages), 'id', 'name');
        
        if(!this.id){
        let languages = this.systemLanguages;
        let self = this;
        languages.forEach(item => {
          self.tempItem.translations.push({
            language_id: item.id,
            name: "",
          })
        })
        this.setstatuses(self.tempItem)
        
      }

    }
}
</script>

<style scoped>
#dashboard-box {
    padding: 10px;
}
</style>
